body {
  overflow-x: hidden !important;
}

/* .top-height{
    margin-top:10%;
} */

.text-decor {
  text-decoration: underline;
  font-family: "Roboto";

}

/* 
.back-color{
    background-color:white;
} */
hr.class-1 {
  border-top: 20px solid #f5f7fa;
  margin: 0px;
}

.carousel-image {

  border-style: none;
  max-width: 100%;
  display: block;
  height: auto;
  width: 1500px;
  opacity: 10;
}

ul {
  list-style-type: "none";
  /* border:none; */
}

/* .top-height{
    margin-top:20%;
} */

.alignment {
  width: 800px;
  height: 100%;
}

.swal-text {
  text-align: center;
  font: 30px bolder -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  ;

}

.swal-button {
  vertical-align: middle;

  border-radius: 2px;
  width: 200px;
  background-color: rgb(37, 116, 139);
  font-size: 20px;
  /* border: 1px solid rgb(7, 143, 18);*/
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

/* .swal-button--confirm:hover{
   background-color:'green'; 
  
   } */
.swal-footer {
  /* background-color: #1ee6a3 !important; */
  margin-top: 26px;
  border-top: 1px solid #E9EEF1;
  overflow: hidden;
  text-align: center;
}

/* .back{
   background-image:url('../../assets/dropdown.svg');
    background-position: 0 50%;
    padding-left: 2px;
    background-size: 
    18px 18px;
    background-repeat: no-repeat;
   
    
  } */
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.card-style {
  /* border:lightgray; */
  background-color: "white";
}

/* .card-header{
    padding:0px;
    }
    .card-body{
    padding-right:0px;
    } */
.alignment {
  width: 400px;
  height: 10px;
  border: black;

}

.inner-alignment {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-bottom: 1px solid #d3d3d3 !important;

  /* max-width:500px; */

}


.panel-title {
  position: relative;
}

.panel-title::before {
  content: "\f107";
  color: #333;
  top: -2px;
  left: 0px;
  position: absolute;
  font-family: "FontAwesome";
  padding: 6px;
}

.panel-title .accordion-collpase::before {
  content: "\f106";
}

/* .accordion-open{
        padding-right: 0 !important;
      } */

.btn {
  width: 200px;
  background-color: rgba(29, 19, 165, 0.87);
  border: none;
  margin-top: 30px;
}

#p_wrap {
  white-space: pre-line;
}

/* .x-accordion-collapse::before{
        content:"\f107";
        transform: rotate(180deg);
        color: #333;
        top: -2px;
        left: 0px;
        position: absolute;
          font-family: "FontAwesome"
      } */
/* .panel-title[data-toggle="true"]::before {
        content: "\f106";
      } */

.font-style {
  font-family: 'Roboto';
  font-size: 14px;
  margin-left: -20px;
  color: #68707d;

}

.font-style-no-margin {
  font-family: 'Roboto';
  font-size: 15px;
  padding: 6px;
  display: inline-block;
  color: black;
}

/* .container{
        background-color:#F6FCFB;
      } */
.accordion-length {
  width: 900px;
  height: 100%;
  padding-bottom: 30px;

}

.inner-accordion-length {
  width: 800px;
  height: 100%;
}

.acc {
  border-left-color: rgb(11, 192, 132);
  min-height: 10px;
}

.header-images {
  float: right;
  padding-top: 1%;
  padding-right: 1%;
}

.modal-btn {
  width: 30px;
  padding-right: 0;
}

/* .back:hover::after {transform: rotate(180deg); cursor: pointer;} */
.carousel .slide img {
  max-height: 500px;
  /* change this to whatever you want */
  width: auto;
}

.ondelete {
  filter: blur(8px);
}


.imgheight {
  height: 150px !important;
}

.card-styling {
  background-color: #ffffff;

  margin-bottom: 20px;
}

.icon-style {
  color: #68707d;
  padding: 10px;
}

.card-header:hover {
  cursor: pointer;
}

.accordion>.card:not(:last-of-type) {
  border: 1px solid rgba(0, 0, 0, .125);
}

.main-card {
  background-color: #f3f3f3;
}

.rotate-icon {
  transform: rotate(180deg);
}

/*Media Queries for responsiveness*/
@media only screen and (max-width: 1237px) {
  .accordion-length {
    width: 800px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 750px;
  }
}

@media only screen and (max-width: 1024px) {
  .accordion-length {
    width: 700px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 650px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-length {
    width: 500px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 450px;
  }
}

@media only screen and (max-width: 576px) {
  .accordion-length {
    width: 100%;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 100%;
  }
}


.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.swal {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color: #ffffff !important;
}

/* ----------FAQ Preview --------- */

.acc-faqs {
  display: flex;
  justify-content: space-between;
  padding: 0 .4rem;
}

/* --------------- */


/* ---------- Preview pages ----------- */



.carousel-status {
  display: none;
}

.carousel .control-dots {
  transform: translateX(-6%);
}


.ql-editor ul li::marker {
  content: '' !important;
}

.app-page-preview .ql-video {
  min-width: 300px;
}


@media (min-width: 700px) {

  .app-page-preview .ql-video {
    height: 400px;
    width: 600px;
  }

}

/* --------------- FAQ New Accordinan Design ------------------- */



.faq-question {
  font-size: 1.4rem;
  border-top: 1px solid #dbdbdb;
  padding-top: .6rem;
  padding-bottom: .6rem;
  display: flex;
  justify-content: space-between;
}

.faq-answer {
  margin-bottom: .6rem;
  padding: .4rem 0;
  padding-bottom: 1rem;
}

@media(max-width: 600px) {

  .faq-question{
    max-width: 340px;
  }
  
  .faq-ques-span {
    display: inline-block;
    max-width: 300px;
    overflow-x: hidden;
    word-break: break-all;
    padding-right: 1rem;
  }

  .faq-answer{
    max-width: 300px;
    overflow-x: hidden;
    word-break: break-all;
  }

}