.inline-block {
  display: inline-block;
}

.padding {
  padding: 5%;
}

.text-center {
  text-align: center;
}
.textsize12 {
  font-size: 12px;
}

.textsize10 {
  font-size: 10px;
}
.dashboard-text{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #aeaeae;
}
.textsize11 {
  font-size: 11px;
}
.geencolour {
  color: #00a884;
}

.bluecolor {
  color: #1657d5;
}
.tableheight {
  height: 100%;
}

.line-break {
  list-style: none;
  margin: 10px;
  max-width: 100%;
  height: 2px;
  border: solid 1px #dbdfe9;
}

.chartjs-render-monitor {
  background-color: white;
}
