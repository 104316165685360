#sidebar {
  max-height: 100%;
}

@media all and (min-width: 760px) {
  .span {
    position: fixed;
    max-height: 100%;
    display: inline-block;
    width: 10%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    border: 1px solid rgb(153, 148, 148);
    background-color: rgba(255, 255, 255, 0.938);
  }
}
@media not all and (min-width: 760px) {
  .span {
    position: fixed;
    max-height: 100%;
    display: inline-block;
    width: 10%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    border: 1px solid rgb(153, 148, 148);
    background-color: rgba(255, 255, 255, 0.938);
  }
}

/* #sidebar.line-break-logout{
    list-style: none;
    margin: 30px 20px;
    max-width: 70%;
    height: 2px;
    border: solid 1px #dbdfe9;
  } */

.list-group-item,
.list-group-menuitem {
  color: #94979b;
  background-color: transparent !important;
  text-decoration: none;
  font-size: 10px;
  border-color: transparent;
}

/* a.active,
 a:hover,
 .list-group-menuitem :hover,
 .list-group-item :hover {
    color: #0cb691 !important;
    background-color: white !important;
    text-decoration: none;
    border:none
   

  } */
.a:hover,
.li,
.list-group-item:hover,
.list-group-menuitem :hover {
  color: #26a881 !important;
  text-decoration: none;
  background-color: #f5f7fa !important;
}
.list-group-item.active {
  color: #26a881;
  border-color: #f5f7fa;
  background-color: #f5f7fa !important;
}
.margin-right{
  margin-right:15px;
}
.font-size{
  font-size: 14px !important;
}
.MuiListItem-gutters {
  padding-left: 28px !important;
  
}
.activeTab {
  border-bottom-color: #00a884 !important;
  color: #00a884 !important;
  border-bottom-width: 2px !important;
}

.Rectangle-Copy {
  border-radius: 2px;
  box-shadow: 0 1px 4px 2px rgba(34, 57, 79, 0.2);
  background-color: #1657d5;
}
.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: bottom;
}
.swal {

  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
  
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}
