select::before { 
  /* position: absolute;
content: ""; */
/* width: 80px;
height: 80px; */
 /* background-position: 75% 40%;
  background-image: url('../../assets/dropdown.svg');
  background-repeat: no-repeat;
background-color: grey; */
}
select {
  /* styling */
  background-color: rgb(182, 161, 161);
  border: solid black;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.font-awesome-back{
background-color:lightgrey;
/* border-radius: 6rem; */
padding:10px;
}
hr.class-1 { 
  border-top: 20px solid #F6FCFB; 
  margin:0px;
} 
/* arrows */
select.classic {
  background-image:
    linear-gradient(to left, lightgrey, lightgrey);
  background-position:
    /* calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), */
    0 80%;
  background-size:
    /* 5px 5px,
    5px 5px, */
    2.5em 2.5em;
  background-repeat: no-repeat;
}
select.alignment{
  width: 100%;
  height:100%;
}
.alignment{
  width: 1000px;
  height:80px;
  border:none;
}
.font-style{
  font-family:Arial, Helvetica, sans-serif; 
  /* font-size:20px; */
}
/* .div-style{
  margin-top:10px;
  background-color: rgb(230, 243, 242);
} */
/* .container{
background-color:#f6fcfb;
} */
.back{
  background-image:
 linear-gradient(to left, black, black);
  background-position:
  calc(100% - 15px) 1em,
  calc(100% - 20px) 1em,
   0 80%;
background-size:
   5px 5px,
  5px 5px,
  2.5em 2.5em;
background-repeat: no-repeat;
}
.grad1 {
  height: 20px;
  width:20px;
  background-color: lightgrey; 
  background-image: linear-gradient(lightgrey, lightgrey);
}
/* .back::before{
  transform:rotate(180deg);
  top:-6px; 
}
 Accordion::before{
  background-image:
  linear-gradient(to left, grey, grey);
   background-position:
   calc(100% - 15px) 1em,
   calc(100% - 20px) 1em,
    0 80%;
 } */
 /* #Accordion-toggle::before{
  transform:rotate(180deg);
  top:-6px; 
 } */
 i .onClick{
  transform:rotate(180deg);
  top:-6px;
 }
 .accordion-length{
  width:100%;
  height:100%;
}
/* @media screen and (max-width:992px){
.accordion-length{
  width:700px;
  height:100%;
}
}
@media screen and (max-width:600px){
  .accordion-length{
    width:500px;
    height:100%;
  }
  } */
 
 .arrow-background{
  background-image:
  linear-gradient(to right, lightgrey, lightgrey);
  position:left;
  width:20px;
  height:20px;
 }
select.classic:focus {
background-image:
  /* linear-gradient(45deg, black 50%, transparent 50%),
  linear-gradient(135deg, transparent 50%, black 50%), */
  linear-gradient(to right, rgb(14, 12, 12), lightgrey);
background-position:
  /* calc(100% - 15px) 1em,
  calc(100% - 20px) 1em, */
   0  100%;
background-size:
  /* 5px 5px,
  5px 5px, */
  2.5em 2.5em;
background-repeat: no-repeat;
border-color: lightgrey;
outline: 0;
}
/* .customSelect.accordion.toggle:before{
content:'';
background: lightgrey;
position:absolute;
top:0;
bottom:0;
right:0;
width:40px;
}  */
/* .delete-image{
padding-left:90%;
padding-bottom:30px;
}
.block-image{
padding-bottom:40px;
padding-left:100%;
}
.edit-image{
padding-left:70%;
}
.add-image{
margin-bottom:30px;
padding-left:86%;
} */
/* * {
  box-sizing: border-box;
}
.col {
  float: right;
  width: 22.22%;
  padding: 5px;
}
.rw::after {
  content: "";
  clear: both;
  display: table;
} */
.header-images{
  float:right;
  padding-top:1%;
  padding-right: 1%;
}
.images{
float:right;
padding-right:1%;
}
ul{
list-style-type:none;
}
Link{
text-decoration: none;
}
.card-style{
border:none;
background-color: white;
}
.card-header{
padding:0px;
}
.card-body{
padding-right:0px;
}
li{
top:5%;
}
.delete-image{
  content:url('../../assets/delete.svg');
  width:40px;
  height:40px;
  position:absolute;
  z-index:100;
}
.block-image{
padding-bottom:40px;
padding-left:100%;
position:absolute;
  z-index:100;
}
.edit-image{
padding-left:70%;
position:absolute;
  z-index:100;
}
.add-image{
margin-bottom:30px;
padding-left:86%;
position:absolute;
  z-index:100;
} */
/* * {
  box-sizing: border-box;
}
.col {
  float: right;
  width: 22.22%;
  padding: 5px;
}
.rw::after {
  content: "";
  clear: both;
  display: table;
} */
.images{
float:right;
padding:1%;
}
ul{
list-style-type:none;
}
Link{
text-decoration: none;
}
.card-style{
border:none;
}
li{
top:5%;
}
.btn
{
  margin-top:10%;
}
.h6-top{
  margin-top:10%;
}
option { direction: ltr; }
.span{
  margin-top: 20px;
}
.image-alignment{
  padding-top:20px;
}
.inpSelect { 
 /* padding:auto;
  display:block;
  color: black;  */
  background-position: left;
  background-image: url('../../assets/dropdown.svg');
  background-repeat: no-repeat;
  /* position: absolute; */
} 
/* @media all and (min-width: 760px) {
  select{
      margin-top:30%;
  }
}
@media not all and (min-width: 760px) {
  select{
      margin-top:30%;
  }
}  */
/* .customSelect select{
  width:100%;
  height:100%;
  background: none;
  border:none;
  -webkit-appearance: none;
  padding:0 50px 0 20px;
  cursor:pointer;
  font:300 18px/1.5 'Roboto' 'sans-serif';
  text-indent: 5px;
} */
/*
.customSelect  select:focus{
  outline:none;
}
.customSelect option{
  font:300 18px/1.5 'Roboto' 'sans-serif';
}
.customSelect .select{
  position: relative;
  background: #fff;
  border:1px black;
  height:50px;
  cursor:pointer;
}*/
/* .customSelect.select:before{
  content:'';
  background: grey;
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  width:40px;
}  */
/* .customSelect .select:after{
  content:'';
  width:0;
  height:0;
  border-style: solid;
  border-width: 10px 7px 0;
  border-color: #fff  ;
  position: absolute;
  top:50%;
  right:11px;
  transform:translateY(-50%);
} */
/* .styled-select {
overflow: hidden;
overflow: -moz-hidden-unscrollable;
background: url('../../assets/dropdown.svg') no-repeat right grey;
border: 2px ;
display: inline-block;
position: relative;
}
.styled-select select {
background: transparent;
-webkit-appearance: none;
width: 100px;
font-size: 11px;
border: 0;
height: 17px;
position: absolute;
left: 0;
top: 0;
} */
/* div{
  bottom:20px;
  top:20px;
} */
/* div{
  bottom:20px;
  top:20px;
} */
.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.swal-button--confirm {
  background-color: #1657d5;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.swal{
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}
