.top-height{
top:20%;
}
.--confirm {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    vertical-align: bottom;
  }
  
  .swal{
    border: "#d7d7d7";
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    font-family: "Roboto";
    height: 45px;
    text-align: center;
  }
  
  .allign {
    padding-top: 5%;
    height: inherit;
    cursor: pointer;
  }
  
  #createMsg {
    max-width: 800px !important;
  }
  
  .activeMessageTab {
    background-color: #1657d5;
    color:#ffffff !important;
  }
  