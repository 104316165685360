@import url(https://fonts.googleapis.com/css?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
.inline-block {
  display: inline-block;
}

.padding {
  padding: 5%;
}

.text-center {
  text-align: center;
}
.textsize12 {
  font-size: 12px;
}

.textsize10 {
  font-size: 10px;
}
.dashboard-text{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #aeaeae;
}
.textsize11 {
  font-size: 11px;
}
.geencolour {
  color: #00a884;
}

.bluecolor {
  color: #1657d5;
}
.tableheight {
  height: 100%;
}

.line-break {
  list-style: none;
  margin: 10px;
  max-width: 100%;
  height: 2px;
  border: solid 1px #dbdfe9;
}

.chartjs-render-monitor {
  background-color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 122px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 50%;
  left: 50%;
  bottom: 0px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(-100%);
  background-color: #26a881;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 34px;
  background-color:#2B57D5;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #aeaeae;
  border-radius: 50%;
  display: inline-block;
}
.margin-bottom{
  margin-bottom: 20px;
}

.thumbs-wrapper{
  visibility: hidden !important;
  height: 0px;

}
.activeTab {
  border-bottom-color: #00a884 !important;
  color: #00a884 !important;
  border-bottom-width: 2px !important;
}

.Rectangle-Copy {
  border-radius: 2px;
  box-shadow: 0 1px 4px 2px rgba(34, 57, 79, 0.2);
  background-color: #1657d5;
}
.Delete {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.messageTypes {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}

.ck-content
{
  height: 200px;
}
 .ck-content a {
  color:#0F836A
}
.align-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
textarea { resize: none; }
/* .modal-body{
  resize: both;
  overflow: none;
} */
.text-div{
 
/* border: 0px solid #ccc; */
font: medium -moz-fixed;
border: none;
overflow: hidden;
resize: none;
box-shadow: inset  white;
}
select::before { 
  /* position: absolute;
content: ""; */
/* width: 80px;
height: 80px; */
 /* background-position: 75% 40%;
  background-image: url('../../assets/dropdown.svg');
  background-repeat: no-repeat;
background-color: grey; */
}
select {
  /* styling */
  background-color: rgb(182, 161, 161);
  border: solid black;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.font-awesome-back{
background-color:lightgrey;
/* border-radius: 6rem; */
padding:10px;
}
hr.class-1 { 
  border-top: 20px solid #F6FCFB; 
  margin:0px;
} 
/* arrows */
select.classic {
  background-image:
    linear-gradient(to left, lightgrey, lightgrey);
  background-position:
    /* calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), */
    0 80%;
  background-size:
    /* 5px 5px,
    5px 5px, */
    2.5em 2.5em;
  background-repeat: no-repeat;
}
select.alignment{
  width: 100%;
  height:100%;
}
.alignment{
  width: 1000px;
  height:80px;
  border:none;
}
.font-style{
  font-family:Arial, Helvetica, sans-serif; 
  /* font-size:20px; */
}
/* .div-style{
  margin-top:10px;
  background-color: rgb(230, 243, 242);
} */
/* .container{
background-color:#f6fcfb;
} */
.back{
  background-image:
 linear-gradient(to left, black, black);
  background-position:
  calc(100% - 15px) 1em,
  calc(100% - 20px) 1em,
   0 80%;
background-size:
   5px 5px,
  5px 5px,
  2.5em 2.5em;
background-repeat: no-repeat;
}
.grad1 {
  height: 20px;
  width:20px;
  background-color: lightgrey; 
  background-image: linear-gradient(lightgrey, lightgrey);
}
/* .back::before{
  transform:rotate(180deg);
  top:-6px; 
}
 Accordion::before{
  background-image:
  linear-gradient(to left, grey, grey);
   background-position:
   calc(100% - 15px) 1em,
   calc(100% - 20px) 1em,
    0 80%;
 } */
 /* #Accordion-toggle::before{
  transform:rotate(180deg);
  top:-6px; 
 } */
 i .onClick{
  transform:rotate(180deg);
  top:-6px;
 }
 .accordion-length{
  width:100%;
  height:100%;
}
/* @media screen and (max-width:992px){
.accordion-length{
  width:700px;
  height:100%;
}
}
@media screen and (max-width:600px){
  .accordion-length{
    width:500px;
    height:100%;
  }
  } */
 
 .arrow-background{
  background-image:
  linear-gradient(to right, lightgrey, lightgrey);
  position:left;
  width:20px;
  height:20px;
 }
select.classic:focus {
background-image:
  /* linear-gradient(45deg, black 50%, transparent 50%),
  linear-gradient(135deg, transparent 50%, black 50%), */
  linear-gradient(to right, rgb(14, 12, 12), lightgrey);
background-position:
  /* calc(100% - 15px) 1em,
  calc(100% - 20px) 1em, */
   0  100%;
background-size:
  /* 5px 5px,
  5px 5px, */
  2.5em 2.5em;
background-repeat: no-repeat;
border-color: lightgrey;
outline: 0;
}
/* .customSelect.accordion.toggle:before{
content:'';
background: lightgrey;
position:absolute;
top:0;
bottom:0;
right:0;
width:40px;
}  */
/* .delete-image{
padding-left:90%;
padding-bottom:30px;
}
.block-image{
padding-bottom:40px;
padding-left:100%;
}
.edit-image{
padding-left:70%;
}
.add-image{
margin-bottom:30px;
padding-left:86%;
} */
/* * {
  box-sizing: border-box;
}
.col {
  float: right;
  width: 22.22%;
  padding: 5px;
}
.rw::after {
  content: "";
  clear: both;
  display: table;
} */
.header-images{
  float:right;
  padding-top:1%;
  padding-right: 1%;
}
.images{
float:right;
padding-right:1%;
}
ul{
list-style-type:none;
}
Link{
text-decoration: none;
}
.card-style{
border:none;
background-color: white;
}
.card-header{
padding:0px;
}
.card-body{
padding-right:0px;
}
li{
top:5%;
}
.delete-image{
  content:url(/static/media/delete.2615db64.svg);
  width:40px;
  height:40px;
  position:absolute;
  z-index:100;
}
.block-image{
padding-bottom:40px;
padding-left:100%;
position:absolute;
  z-index:100;
}
.edit-image{
padding-left:70%;
position:absolute;
  z-index:100;
}
.add-image{
margin-bottom:30px;
padding-left:86%;
position:absolute;
  z-index:100;
} */
/* * {
  box-sizing: border-box;
}
.col {
  float: right;
  width: 22.22%;
  padding: 5px;
}
.rw::after {
  content: "";
  clear: both;
  display: table;
} */
.images{
float:right;
padding:1%;
}
ul{
list-style-type:none;
}
Link{
text-decoration: none;
}
.card-style{
border:none;
}
li{
top:5%;
}
.btn
{
  margin-top:10%;
}
.h6-top{
  margin-top:10%;
}
option { direction: ltr; }
.span{
  margin-top: 20px;
}
.image-alignment{
  padding-top:20px;
}
.inpSelect { 
 /* padding:auto;
  display:block;
  color: black;  */
  background-position: left;
  background-image: url(/static/media/dropdown.d3442936.svg);
  background-repeat: no-repeat;
  /* position: absolute; */
} 
/* @media all and (min-width: 760px) {
  select{
      margin-top:30%;
  }
}
@media not all and (min-width: 760px) {
  select{
      margin-top:30%;
  }
}  */
/* .customSelect select{
  width:100%;
  height:100%;
  background: none;
  border:none;
  -webkit-appearance: none;
  padding:0 50px 0 20px;
  cursor:pointer;
  font:300 18px/1.5 'Roboto' 'sans-serif';
  text-indent: 5px;
} */
/*
.customSelect  select:focus{
  outline:none;
}
.customSelect option{
  font:300 18px/1.5 'Roboto' 'sans-serif';
}
.customSelect .select{
  position: relative;
  background: #fff;
  border:1px black;
  height:50px;
  cursor:pointer;
}*/
/* .customSelect.select:before{
  content:'';
  background: grey;
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  width:40px;
}  */
/* .customSelect .select:after{
  content:'';
  width:0;
  height:0;
  border-style: solid;
  border-width: 10px 7px 0;
  border-color: #fff  ;
  position: absolute;
  top:50%;
  right:11px;
  transform:translateY(-50%);
} */
/* .styled-select {
overflow: hidden;
overflow: -moz-hidden-unscrollable;
background: url('../../assets/dropdown.svg') no-repeat right grey;
border: 2px ;
display: inline-block;
position: relative;
}
.styled-select select {
background: transparent;
-webkit-appearance: none;
width: 100px;
font-size: 11px;
border: 0;
height: 17px;
position: absolute;
left: 0;
top: 0;
} */
/* div{
  bottom:20px;
  top:20px;
} */
/* div{
  bottom:20px;
  top:20px;
} */
.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.swal-button--confirm {
  background-color: #1657d5;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.swal{
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}

body {
  overflow-x: hidden !important;
}

/* .top-height{
    margin-top:10%;
} */

.text-decor {
  text-decoration: underline;
  font-family: "Roboto";

}

/* 
.back-color{
    background-color:white;
} */
hr.class-1 {
  border-top: 20px solid #f5f7fa;
  margin: 0px;
}

.carousel-image {

  border-style: none;
  max-width: 100%;
  display: block;
  height: auto;
  width: 1500px;
  opacity: 10;
}

ul {
  list-style-type: "none";
  /* border:none; */
}

/* .top-height{
    margin-top:20%;
} */

.alignment {
  width: 800px;
  height: 100%;
}

.swal-text {
  text-align: center;
  font: 30px bolder -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  ;

}

.swal-button {
  vertical-align: middle;

  border-radius: 2px;
  width: 200px;
  background-color: rgb(37, 116, 139);
  font-size: 20px;
  /* border: 1px solid rgb(7, 143, 18);*/
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

/* .swal-button--confirm:hover{
   background-color:'green'; 
  
   } */
.swal-footer {
  /* background-color: #1ee6a3 !important; */
  margin-top: 26px;
  border-top: 1px solid #E9EEF1;
  overflow: hidden;
  text-align: center;
}

/* .back{
   background-image:url('../../assets/dropdown.svg');
    background-position: 0 50%;
    padding-left: 2px;
    background-size: 
    18px 18px;
    background-repeat: no-repeat;
   
    
  } */
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.card-style {
  /* border:lightgray; */
  background-color: "white";
}

/* .card-header{
    padding:0px;
    }
    .card-body{
    padding-right:0px;
    } */
.alignment {
  width: 400px;
  height: 10px;
  border: black;

}

.inner-alignment {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-bottom: 1px solid #d3d3d3 !important;

  /* max-width:500px; */

}


.panel-title {
  position: relative;
}

.panel-title::before {
  content: "\f107";
  color: #333;
  top: -2px;
  left: 0px;
  position: absolute;
  font-family: "FontAwesome";
  padding: 6px;
}

.panel-title .accordion-collpase::before {
  content: "\f106";
}

/* .accordion-open{
        padding-right: 0 !important;
      } */

.btn {
  width: 200px;
  background-color: rgba(29, 19, 165, 0.87);
  border: none;
  margin-top: 30px;
}

#p_wrap {
  white-space: pre-line;
}

/* .x-accordion-collapse::before{
        content:"\f107";
        transform: rotate(180deg);
        color: #333;
        top: -2px;
        left: 0px;
        position: absolute;
          font-family: "FontAwesome"
      } */
/* .panel-title[data-toggle="true"]::before {
        content: "\f106";
      } */

.font-style {
  font-family: 'Roboto';
  font-size: 14px;
  margin-left: -20px;
  color: #68707d;

}

.font-style-no-margin {
  font-family: 'Roboto';
  font-size: 15px;
  padding: 6px;
  display: inline-block;
  color: black;
}

/* .container{
        background-color:#F6FCFB;
      } */
.accordion-length {
  width: 900px;
  height: 100%;
  padding-bottom: 30px;

}

.inner-accordion-length {
  width: 800px;
  height: 100%;
}

.acc {
  border-left-color: rgb(11, 192, 132);
  min-height: 10px;
}

.header-images {
  float: right;
  padding-top: 1%;
  padding-right: 1%;
}

.modal-btn {
  width: 30px;
  padding-right: 0;
}

/* .back:hover::after {transform: rotate(180deg); cursor: pointer;} */
.carousel .slide img {
  max-height: 500px;
  /* change this to whatever you want */
  width: auto;
}

.ondelete {
  -webkit-filter: blur(8px);
          filter: blur(8px);
}


.imgheight {
  height: 150px !important;
}

.card-styling {
  background-color: #ffffff;

  margin-bottom: 20px;
}

.icon-style {
  color: #68707d;
  padding: 10px;
}

.card-header:hover {
  cursor: pointer;
}

.accordion>.card:not(:last-of-type) {
  border: 1px solid rgba(0, 0, 0, .125);
}

.main-card {
  background-color: #f3f3f3;
}

.rotate-icon {
  transform: rotate(180deg);
}

/*Media Queries for responsiveness*/
@media only screen and (max-width: 1237px) {
  .accordion-length {
    width: 800px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 750px;
  }
}

@media only screen and (max-width: 1024px) {
  .accordion-length {
    width: 700px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 650px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-length {
    width: 500px;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 450px;
  }
}

@media only screen and (max-width: 576px) {
  .accordion-length {
    width: 100%;
    height: 100%;

  }

  .inner-alignment {
    height: 100%;
    max-width: 100%;
  }
}


.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.swal {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color: #ffffff !important;
}

/* ----------FAQ Preview --------- */

.acc-faqs {
  display: flex;
  justify-content: space-between;
  padding: 0 .4rem;
}

/* --------------- */


/* ---------- Preview pages ----------- */



.carousel-status {
  display: none;
}

.carousel .control-dots {
  transform: translateX(-6%);
}


.ql-editor ul li::marker {
  content: '' !important;
}

.app-page-preview .ql-video {
  min-width: 300px;
}


@media (min-width: 700px) {

  .app-page-preview .ql-video {
    height: 400px;
    width: 600px;
  }

}

/* --------------- FAQ New Accordinan Design ------------------- */



.faq-question {
  font-size: 1.4rem;
  border-top: 1px solid #dbdbdb;
  padding-top: .6rem;
  padding-bottom: .6rem;
  display: flex;
  justify-content: space-between;
}

.faq-answer {
  margin-bottom: .6rem;
  padding: .4rem 0;
  padding-bottom: 1rem;
}

@media(max-width: 600px) {

  .faq-question{
    max-width: 340px;
  }
  
  .faq-ques-span {
    display: inline-block;
    max-width: 300px;
    overflow-x: hidden;
    word-break: break-all;
    padding-right: 1rem;
  }

  .faq-answer{
    max-width: 300px;
    overflow-x: hidden;
    word-break: break-all;
  }

}
.top-height{
top:20%;
}
.--confirm {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    vertical-align: bottom;
  }
  
  .swal{
    border: "#d7d7d7";
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    font-family: "Roboto";
    height: 45px;
    text-align: center;
  }
  
  .allign {
    padding-top: 5%;
    height: inherit;
    cursor: pointer;
  }
  
  #createMsg {
    max-width: 800px !important;
  }
  
  .activeMessageTab {
    background-color: #1657d5;
    color:#ffffff !important;
  }
  
@font-face {
  font-family: Proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

body{
  font-family: 'Proxima';
}

a:link{
  color: #0F836A
}

.App {
  text-align: center;
}
.App-background-color{
  background-color:#f5f7fa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.error-message{
  margin-left:130px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}
.login-input{
  outline: none;
}
.css-yk16xz-control{
  border:1px solid black !important;
  border-radius: 0px !important;
}
/* @import url('https://kit.fontawesome.com/a076d05399.js'); */
/* @import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'); */
/* @import url("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"); */
/* @import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"); */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-group-item {
  padding: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn.bg-success:hover {
  box-shadow: 0 0 20px var(--success) !important;
}


.btn.bg-danger {
  margin-top: 0px !important;
  background-color: #1657d5 !important;
}

.btn.bg-warning 
{
  margin-top: 0px !important;
  background-color: #00a884 !important;
}

.btn.bg-warning:hover {
  box-shadow: 0 0 20px #00a884 !important;
}

.btn.bg-danger:hover {
  box-shadow: 0 0 20px #1657d5 !important;
}

.btn.close:hover {
  background-color: red !important;
  color: white;
  width: 50px !important;
}

.-next button {
  background-color:#26A881 !important;
}
.-previous button {
  background-color: #2B57D5 !important;
}


.-next button:hover {
  background-color: #24aa82 !important;
}

.-next button:disabled
{
  background-color: rgba(0,0,0,0.1) !important
}
.-next button:disabled:hover
{
  background-color: rgba(0,0,0,0.1) !important
}

.-previous button:disabled
{
  background-color: rgba(0,0,0,0.1) !important
}
.-previous button:disabled:hover
{
  background-color: rgba(0,0,0,0.1) !important
}

.-previous button:hover {
  background-color: #2454da !important;
  color:white;
}

.bg-component {
  background-color: #f5f7fa;
}
.box-style {
  border: none;

  color: rgb(107, 105, 105);
  font-weight: bolder;
  /* font-weight: bolder gray; */

  color: gray;
  font-weight: bolder;
}

.rt-thead {
  color: #aeaeae;
  font-size: 14px;
  height: 60px;
  font-family: "Roboto";
  font-weight: 500;
  /* padding: 20px; */
}
.logo{
  max-width:100px;
  max-height:50px;
}

.rt-td {
  /* padding: 20px !important; */
  /* height:40px; */

  margin-top: 8px;
  margin-bottom: 8px;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none !important;
}

::-webkit-input-placeholder {
  color: #dddddd;
  font-size: 18px;
}

:-ms-input-placeholder {
  color: #dddddd;
  font-size: 18px;
}

::-ms-input-placeholder {
  color: #dddddd;
  font-size: 18px;
}

::placeholder {
  color: #dddddd;
  font-size: 18px;
}

.roundCheck {
  position: relative;
}

.roundCheck label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundCheck label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundCheck input[type="checkbox"] {
  visibility: hidden;
}

.roundCheck input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.roundCheck input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.ReactTable .rt-thead .rt-th {
  padding-top: 20px !important;
}

.user-table .rt-thead {
  min-width: 1200px !important;
}
.user-table .rt-tbody {
  min-width: 1200px !important;
}
.user-table .rt-table {
  overflow: auto !important;
}

.rt-table {
  overflow: visible;
}

.rt-noData {
  background-color: transparent !important;
}

.rt-noData {
  background-color: transparent !important;
}


.swal-button--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: bottom;
}
.--confirm {
  background-color: #1657d5 !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;

}


.--confirm:hover {
  box-shadow: 0 0 20px #1657d5 !important;
}

.--cancel:hover {
  box-shadow: 0 0 20px #00a884 !important;
  
}
.swal {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.swal-button--confirm {
  background-color: #1657d5;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
}

.__react_component_tooltip {
  pointer-events: none;
}

.ReactTable .-sort-desc :before {
  /* box-shadow: none !important; */
  content: "\f106" !important;
  font-family: "FontAwesome";
  float: right;
}
.ReactTable .rt-th :before {
  /* box-shadow: none !important; */
  content: "\f107";
  font-family: "FontAwesome";
  float: right;
}

select {
  -webkit-appearance: menulist !important;
}
.ReactTable .rt-th:not(.-cursor-pointer) :before {
  /* box-shadow: none !important; */
  content: "";
  float: right;
}

.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: disc !important;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

 ul,
 ul li{
  list-style-type: disc !important;
}

 ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

input internal-autofill-selected {
background-color: white !important;
}

.borderlogin {
  border-top: none;
  border-left: none;
  border-right: none;
    /* border-bottom: #707070; */
    border-bottom-color: #707070 !important;
    border-bottom-width: 1px;
    /* font-family: "Proxima Nova"; */
    font-size: 18px;
}
#email::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#email:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#email::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#password::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#password:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#password::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#password::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: black !important;
    font-family: "";
}

#loading .modal-content{
  background-color: transparent !important;
  border:none;
}

input.adInputs::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}

input.adInputs:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}

input.adInputs::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}

input.adInputs::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
textarea.adInputs::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
textarea.adInputs:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
textarea.adInputs::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
textarea.adInputs::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
#sidebar {
  max-height: 100%;
}

@media all and (min-width: 760px) {
  .span {
    position: fixed;
    max-height: 100%;
    display: inline-block;
    width: 10%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    border: 1px solid rgb(153, 148, 148);
    background-color: rgba(255, 255, 255, 0.938);
  }
}
@media not all and (min-width: 760px) {
  .span {
    position: fixed;
    max-height: 100%;
    display: inline-block;
    width: 10%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    border: 1px solid rgb(153, 148, 148);
    background-color: rgba(255, 255, 255, 0.938);
  }
}

/* #sidebar.line-break-logout{
    list-style: none;
    margin: 30px 20px;
    max-width: 70%;
    height: 2px;
    border: solid 1px #dbdfe9;
  } */

.list-group-item,
.list-group-menuitem {
  color: #94979b;
  background-color: transparent !important;
  text-decoration: none;
  font-size: 10px;
  border-color: transparent;
}

/* a.active,
 a:hover,
 .list-group-menuitem :hover,
 .list-group-item :hover {
    color: #0cb691 !important;
    background-color: white !important;
    text-decoration: none;
    border:none
   

  } */
.a:hover,
.li,
.list-group-item:hover,
.list-group-menuitem :hover {
  color: #26a881 !important;
  text-decoration: none;
  background-color: #f5f7fa !important;
}
.list-group-item.active {
  color: #26a881;
  border-color: #f5f7fa;
  background-color: #f5f7fa !important;
}
.margin-right{
  margin-right:15px;
}
.font-size{
  font-size: 14px !important;
}
.MuiListItem-gutters {
  padding-left: 28px !important;
  
}
.activeTab {
  border-bottom-color: #00a884 !important;
  color: #00a884 !important;
  border-bottom-width: 2px !important;
}

.Rectangle-Copy {
  border-radius: 2px;
  box-shadow: 0 1px 4px 2px rgba(34, 57, 79, 0.2);
  background-color: #1657d5;
}
.--confirm {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}
.--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: bottom;
}
.swal {

  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
  
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}

