.activeTab {
  border-bottom-color: #00a884 !important;
  color: #00a884 !important;
  border-bottom-width: 2px !important;
}

.Rectangle-Copy {
  border-radius: 2px;
  box-shadow: 0 1px 4px 2px rgba(34, 57, 79, 0.2);
  background-color: #1657d5;
}
.Delete {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.messageTypes {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.allign {
  padding-top: 5%;
  height: inherit;
  cursor: pointer;
}

#createMsg {
  max-width: 800px !important;
}

.activeMessageTab {
  background-color: #1657d5;
  color:#ffffff !important;
}

.ck-content
{
  height: 200px;
}
 .ck-content a {
  color:#0F836A
}
.align-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
textarea { resize: none; }
/* .modal-body{
  resize: both;
  overflow: none;
} */
.text-div{
 
/* border: 0px solid #ccc; */
font: medium -moz-fixed;
border: none;
overflow: hidden;
resize: none;
-moz-box-shadow: inset  white;
-webkit-box-shadow: inset  white;
box-shadow: inset  white;
}