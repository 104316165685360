@import url("https://fonts.googleapis.com/css?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
/* @import url('https://kit.fontawesome.com/a076d05399.js'); */
/* @import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'); */
/* @import url("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"); */
/* @import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"); */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./components/Dashboard/Dashboard.css";
@import "./components/Job/Job.css";
@import "./components/Messages/Messages.css";
@import "./components/Categories/Category.css";
@import "./components/AppPages/AppPages.css";
@import "./components/Users/User.css";
@import "./App.css";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-group-item {
  padding: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn.bg-success:hover {
  box-shadow: 0 0 20px var(--success) !important;
}


.btn.bg-danger {
  margin-top: 0px !important;
  background-color: #1657d5 !important;
}

.btn.bg-warning 
{
  margin-top: 0px !important;
  background-color: #00a884 !important;
}

.btn.bg-warning:hover {
  box-shadow: 0 0 20px #00a884 !important;
}

.btn.bg-danger:hover {
  box-shadow: 0 0 20px #1657d5 !important;
}

.btn.close:hover {
  background-color: red !important;
  color: white;
  width: 50px !important;
}

.-next button {
  background-color:#26A881 !important;
}
.-previous button {
  background-color: #2B57D5 !important;
}


.-next button:hover {
  background-color: #24aa82 !important;
}

.-next button:disabled
{
  background-color: rgba(0,0,0,0.1) !important
}
.-next button:disabled:hover
{
  background-color: rgba(0,0,0,0.1) !important
}

.-previous button:disabled
{
  background-color: rgba(0,0,0,0.1) !important
}
.-previous button:disabled:hover
{
  background-color: rgba(0,0,0,0.1) !important
}

.-previous button:hover {
  background-color: #2454da !important;
  color:white;
}

.bg-component {
  background-color: #f5f7fa;
}
.box-style {
  border: none;

  color: rgb(107, 105, 105);
  font-weight: bolder;
  /* font-weight: bolder gray; */

  color: gray;
  font-weight: bolder;
}

.rt-thead {
  color: #aeaeae;
  font-size: 14px;
  height: 60px;
  font-family: "Roboto";
  font-weight: 500;
  /* padding: 20px; */
}
.logo{
  max-width:100px;
  max-height:50px;
}

.rt-td {
  /* padding: 20px !important; */
  /* height:40px; */

  margin-top: 8px;
  margin-bottom: 8px;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none !important;
}

::placeholder {
  color: #dddddd;
  font-size: 18px;
}

.roundCheck {
  position: relative;
}

.roundCheck label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundCheck label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundCheck input[type="checkbox"] {
  visibility: hidden;
}

.roundCheck input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.roundCheck input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.ReactTable .rt-thead .rt-th {
  padding-top: 20px !important;
}

.user-table .rt-thead {
  min-width: 1200px !important;
}
.user-table .rt-tbody {
  min-width: 1200px !important;
}
.user-table .rt-table {
  overflow: auto !important;
}

.rt-table {
  overflow: visible;
}

.rt-noData {
  background-color: transparent !important;
}

.rt-noData {
  background-color: transparent !important;
}


.swal-button--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;
}

.--cancel {
  background-color: #00a884 !important;
  color:white !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: bottom;
}
.--confirm {
  background-color: #1657d5 !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: bottom;

}


.--confirm:hover {
  box-shadow: 0 0 20px #1657d5 !important;
}

.--cancel:hover {
  box-shadow: 0 0 20px #00a884 !important;
  
}
.swal {
  border: "#d7d7d7";
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-family: "Roboto";
  height: 45px;
  text-align: center;
}

.swal-button--confirm {
  background-color: #1657d5;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
}

.__react_component_tooltip {
  pointer-events: none;
}

.ReactTable .-sort-desc :before {
  /* box-shadow: none !important; */
  content: "\f106" !important;
  font-family: "FontAwesome";
  float: right;
}
.ReactTable .rt-th :before {
  /* box-shadow: none !important; */
  content: "\f107";
  font-family: "FontAwesome";
  float: right;
}

select {
  -webkit-appearance: menulist !important;
}
.ReactTable .rt-th:not(.-cursor-pointer) :before {
  /* box-shadow: none !important; */
  content: "";
  float: right;
}

.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: disc !important;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

 ul,
 ul li{
  list-style-type: disc !important;
}

 ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

input internal-autofill-selected {
background-color: white !important;
}

.borderlogin {
  border-top: none;
  border-left: none;
  border-right: none;
    /* border-bottom: #707070; */
    border-bottom-color: #707070 !important;
    border-bottom-width: 1px;
    /* font-family: "Proxima Nova"; */
    font-size: 18px;
}
#email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#password::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: black !important;
    font-family: "";
}

#loading .modal-content{
  background-color: transparent !important;
  border:none;
}

input.adInputs::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}
textarea.adInputs::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}