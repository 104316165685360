@font-face {
  font-family: Proxima;
  src: url('./assets/ProximaNova-Regular.otf');
}

body{
  font-family: 'Proxima';
}

a:link{
  color: #0F836A
}

.App {
  text-align: center;
}
.App-background-color{
  background-color:#f5f7fa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.error-message{
  margin-left:130px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}
.login-input{
  outline: none;
}
.css-yk16xz-control{
  border:1px solid black !important;
  border-radius: 0px !important;
}